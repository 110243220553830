import { useState, useEffect, useContext, useMemo } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';
import { useKeyPress } from 'hooks';
import { LanguageContext } from 'context/Language';
import { HeaderSearchInputIcon, HeaderSearchButtonIcon } from 'resources';

import type { InputProps } from './index';

const Icon = dynamic(() => import('components/Icon'));
const Input = dynamic(() => import('components/Input'));

export interface SearchInput extends InputProps {
  searchText?: React.ReactNode;
  visible?: boolean;
  onChangeVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  onSearch?: (value: string) => void;
}

const SearchInput: React.FC<SearchInput> = ({
  visible = true,
  value,
  className,
  searchText,
  onChange,
  onChangeVisible,
  onSearch,
  ...props
}) => {
  const { lang } = useContext(LanguageContext);
  const { push, asPath } = useRouter();
  const [isPressedEnter] = useKeyPress('Enter');
  const [state, setState] = useState(value as string);
  const [visibleSearch, setVisibleSearch] = useState<boolean>(false);
  const onChangeHandler = (e: string) => {
    setState(e);
  };

  const onKeyPress = (): void => {
    if (isPressedEnter) {
      const value = state;
      setState('');

      if (onSearch) {
        onSearch(value);
      } else {
        push(`/${lang}/search?keyword=${value}`);
      }
    }
  };

  useEffect(() => {
    setVisibleSearch(visible);
  }, [visible]);

  useEffect(() => {
    if (asPath.includes('keyword')) {
      setVisibleSearch(false);
      setState(undefined);

      if (onChangeVisible) {
        onChangeVisible(false);
      }
    }
  }, [asPath]);

  const searchBtn = useMemo(
    () => (
      <p className="search-button" onClick={() => onSearch && onSearch(state)}>
        {searchText || <Icon component={HeaderSearchButtonIcon} />}
      </p>
    ),
    [searchText, state, onSearch],
  );

  return (
    <div className={cn('search-box', className, { 'search-box--visible': visibleSearch })}>
      <div className="search-box--mask" onClick={() => onChangeVisible && onChangeVisible(false)} />
      <div className="search-box__container">
        <div className="input-icon">
          <Icon component={HeaderSearchInputIcon} />
        </div>
        <Input
          type="text"
          value={state}
          onKeyPress={onKeyPress}
          onChange={(val: string) => onChangeHandler(val)}
          {...props}
        />
        <>
          {onSearch ? (
            searchBtn
          ) : (
            <Link href={{ pathname: `/${lang}/search`, query: { keyword: state } }}>
              {searchBtn}
            </Link>
          )}
        </>
      </div>
    </div>
  );
};

export default SearchInput;
